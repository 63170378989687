<template>
<div>

  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader/>
    </div>
  </transition>

  <!-- <BgCross
    class="BgCross"
  /> -->

  <!-- Content. -->
  <div
      v-if="page"
      class="page-box moving-background content work" 
      ref="work"
    >
    <div class="container">

      <!-- Inital Content -->
      <div class="top-box">

        <!-- Left Image -->
        <div class="left-image">
          <img :src="featured_media_url" alt="n/a">
        </div>

        <!-- Title and contacts -->
        <div class="main-content">
          <!-- Title & Text -->
          <h1 v-if="subtitle">{{subtitle}}</h1>
          <h2 v-if="title">{{title}}</h2>
          <address
            class="contact-blurb"
            v-if="contact_blurb"
            v-html="contact_blurb"
          ></address>

          <!-- Locations -->
          <div 
            class="locations"
            v-if="locations"
          >
            <div 
              class="location" 
              v-for="(location, index) in locations" :key="index"
            >
              <h3>{{location.title}}</h3>
              <p>{{location.address}}</p>
            </div>
          </div>

        </div>

      </div>

      <!-- List of Contacts -->
      <div 
        class="contacts"
        v-if="contacts"
      >
        <div 
          class="contact"
          v-for="(contact, index) in contacts" :key="index"
        >
          <h2>{{contact.name}}</h2>
          <p>{{contact.job_title}}</p>
          <p>{{contact.email}}</p>
        </div>
      </div>

      <div class="line-split"/>

      <!-- Footer -->
      <SubscribeForm2021
        class="SubscribeForm2021"
      />
      <CopyrightFooter
        class="CopyrightFooter"
        v-if="copyright && copyright !== ''"
        :copyright="copyright"
      />
    <!-- End Container -->
    </div>
  
    

  </div>

</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import Loader from '../components/Loader'
// import BgCross from '../components/BgCross'
import SubscribeForm2021 from '../components/SubscribeForm2021'
import CopyrightFooter from '../components/CopyrightFooter'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import { throttle } from 'lodash'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true,
      page: null,
      scene: null
    }
  },
  computed: {
    title () {
      return this.page?.title?.rendered || ''
    },
    featured_media_url () {
      return this.page?.featured_media_url || ''
    },
    subtitle () {
      return this.page?.acf?.subtitle || ''
    },
    contact_blurb () {
      return this.page?.acf?.contact_blurb || ''
    },
    locations () {
      return this.page?.acf?.locations || []
    }, 
    contacts () {
      return this.page?.acf?.contacts || []
    }, 
    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    }
  },
  methods: {
    async fetchPage () {
      if (this.$route.query.preview) {
        this.page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        this.page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(this.$refs.work, 30, {
                backgroundPositionY: '0%'
              }, {
                backgroundPositionY: '100%'
              })
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration(document.body.scrollHeight)
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },

  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchPage())
  },
  components: {
    Loader,
    // BgCross,
    SubscribeForm2021,
    CopyrightFooter
  }
}
</script>

<style lang="scss" scoped>
  @import 'px-par/index.scss';
  @import "./../styles/theme";
  @import "./../styles/container";
  @import "../styles/buttons";
  @import "../styles/grid";
  @import "../styles/text";
  @import "px-par/index.scss";
  @import "../styles/variables";
  @import "../styles/images";

  .loading {
    @include loading;
  }
  @include loading-page-fade;

  .BgCross {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .page-box {
    padding-top: 175px;
    padding-bottom: 110px;

    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  .container {
    @extend %container;
    @extend %mobile-container;
    /* @extend %mobile-container; */
    display: flex;
    flex-direction: column;
    /* margin-top: 175px; */
    /* margin-bottom: 175px; */

    @media (max-width: 800px) {
      /* margin-top: 110px; */
      /* margin-bottom: 110px; */
    }
  }

  .moving-background {
    background-image: url('./../assets/contact/bg.svg');
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: 268.61px 2106.16px;
    @media (max-width: 800px) {
      background-image: none;
    }
  }

  .top-box {
      width: 100%;
      /* background: red; */
      display: flex;
      flex-direction: row;
      @media (max-width: 800px) { 
        flex-direction: column;
        margin-left: m-grid(0.5);
        width: m-grid(5);
      }

    .left-image {
      @include image-background-container-color-on-image;
      width: 100%;
      max-width: px_par(1320, 440);
      padding-top: px_par(1320, 542.87);

      @media (max-width: 800px) {
        max-width: 100%;
        padding-top: px_par(255, 542.87/2);
      }

      img {
        @include image-background-cover;
      }
    }

    .main-content {
      width: 100%;
      max-width: px_par(1320, 519+110);
      margin-left: px_par(1320, 110);
      margin-top: px_par(1320, 110/100*70);

      @media (max-width: 800px) {
        max-width: 100%;
        margin-left: 0px;
      }

      h1 {
        margin: 0px;
        @include b3;
        font-weight: 400;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: 0.6;
      }
      h2 {
        width: 100%;
        max-width: 519px;
        margin: 0px;
        margin-top: 10px;
        @include t2;
        font-weight: 400;
        /* letter-spacing: -0.01em; */
        letter-spacing: initial;

        @media (max-width: 800px) {
          max-width: 100%;
          @include m-t2;
          letter-spacing: initial;
        }
      }

      .contact-blurb /deep/ {
        margin-top: 45px;
        font-style: normal;
        
        p {
          margin: 0px;
          @include b2;
          @media (max-width: 800px) {
            letter-spacing: initial;
            @include m-b2;
            letter-spacing: initial;
          }
        }

        p + p {
          margin-top: 24px;
        }
      }

      .locations {
        display: flex;
        margin-top: 35px;

        .location {
          width: px_par(614, 614/2);

          h3, p {
            width: px_par(614/2, 614/4);
          }

          h3 {
            margin: 0px;
            @include b3;
            font-family: $font-family-mono;
            font-weight: 400;
            letter-spacing: 0.1em;
            opacity: 0.6;
            text-transform: uppercase;
            @media (max-width: 800px) {
              @include m-b3;
            }
          }

          p {
            margin: 0px;
            @include b3;
            line-height: px(21);
            @media (max-width: 800px) {
              @include m-b3;
            }
          }
        }
      }
    }
  }

  .contacts {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 75px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: space-between;
      width: m-grid(5);
      margin-top: 50px;
      margin-left: m-grid(0.5);

    }

    .contact {
      width: 100%;

      @media (max-width: 1200px) {
        width: 50%;
        margin-top: 25px;
      }

      @media (max-width: 800px) {
        width: 100%;
        margin-top: 25px;
      }

      h2 {
        margin: 0px;
        @include b2;
        font-weight: 400;
        letter-spacing: 0.1em;
        @media (max-width: 800px) {
          @include m-b2;
        }
      }

      p {
        margin: 0px;
        margin-top: 5px;
        @include b3;
        font-weight: 400;
        letter-spacing: 0.1em;
        opacity: 0.6;
        @media (max-width: 800px) {
          @include m-b3;
        }
      }
    }

    .contact + .contact  {
      margin-left: px_par(1320, 110/100*90);

      @media (max-width: 1200px) {
        margin-left: 0px;
      }
    }
  }

  .line-split {
    width: 100%;
    margin-top: 65px;
    opacity: 0.2;
    border-bottom: 1px solid $line-split;

    @media (max-width: 800px) {
      border-bottom: 2px solid $line-split;
    }
  }

  .SubscribeForm2021 {
    margin-top: 65px;
    /* margin-bottom: 65px; */
  }

  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }

  @include link-text-hover-underline ('.content .container address /deep/ a', px(1));
  @media (max-width: 800px) {
    @include link-text-hover-underline ('.content .container address /deep/ a', 0px);
  }

  // Support for alt images
  @include theme('light') {
    .alt-image {
      &:not(.no-background){
        background-color: $light-placeholder;
        @include theme-transition;
      }
    }
  }

  @include theme('dark') {
    .alt-image {
      &:not(.no-background){
        @include theme-transition;
        background-color: $dark-placeholder;
      }
    }
  }

</style>
